import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {_aiPrompt} from './core/chatApi'

export type ChatMessageModel = {
  id: string | null
  text: string
  role: 'user' | 'assistant' | 'system'
  previousResponseId?: string | null
  status: 'pending' | 'completed' | 'error'
}

type ChatStore = {
  developer: string
  topic: {
    object: string
    title: string
  }
  discussion: ChatMessageModel[]
  setup: (developer: string, topic: {object: string; title: string}) => void
  setDiscussion: (discussion: ChatMessageModel[]) => void
  postPrompt: (account: string, message: string, developer: string) => Promise<ChatMessageModel[]> // Updated return type
  addMessage: (message: ChatMessageModel) => ChatMessageModel[] // New method to add a message
  removeLastMessage: () => ChatMessageModel[] // New method to remove the last message
}

const createStore: StateCreator<ChatStore> = (set, get) => ({
  developer: '',
  topic: {
    object: '',
    title: '',
  },
  discussion: [],
  setDiscussion: (discussion: ChatMessageModel[]) => {
    set({discussion})
  },
  setup: (developer: string, topic: {object: string; title: string}) => {
    set({developer, topic})
  },
  addMessage: (message: ChatMessageModel) => {
    const {discussion} = get()
    const updatedDiscussion = [...discussion, message]
    set({discussion: updatedDiscussion})
    return updatedDiscussion
  },

  removeLastMessage: () => {
    const {discussion} = get()
    const updatedDiscussion = discussion.slice(0, -1)
    set({discussion: updatedDiscussion})
    return updatedDiscussion
  },

  postPrompt: async (account: string, message: string, developer: string) => {
    const {discussion, removeLastMessage} = get()
    const lastMessage = discussion[discussion.length - 1]
    const lastMessageId = lastMessage ? lastMessage.id : null

    const payload = {
      account,
      prompt: message,
      previousResponseId: lastMessageId,
      developer,
    }
    const response = await _aiPrompt(payload)

    // remove last message from discussions
    const updatedDiscussion = removeLastMessage()

    // add new message from response
    const newMessage: ChatMessageModel = {
      id: response.data.id,
      text: response.data.output,
      role: 'assistant',
      previousResponseId: lastMessageId,
      status: 'completed',
    }
    updatedDiscussion.push(newMessage)

    // update state
    set({discussion: updatedDiscussion})

    // return updated discussion
    return updatedDiscussion
  },
})

export const chatStore = create(devtools(createStore))
export const useChatStore = chatStore
