import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {HeaderUserMenu, Search} from '../../../partials'
import {useLayout} from '../../core'
import {useAccountsUsersStore} from '../../../../app/modules/settings/core/accountsUsersStore'
import {useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Breadcrumbs} from '../../../../_components/SBPageTitle/Breadcrumbs'
import {useAppSettingsStore} from '../../../../app/modules/settings/core/appSettingsStore'
import {isMobileDevice} from '../../../assets/ts/_utils'
import {formatMoney, formatNumber, trimCharacters} from '../../../../_helpers/_helpers'
import {CreateMenu} from '../../../partials/create-menu/CreateMenu'
import {useDashboardsStore} from '../../../../app/pages/dashboard/core/dashboardStore'
import Tippy from '@tippyjs/react'

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {selectedAccountsUsers, adminAccount, switchUserToAdmin, getCurrentAccount} =
    useAccountsUsersStore()
  const [imageExists, setImageExists] = useState(true)
  const {breadcrumbs, currentPage} = useAppSettingsStore()
  const {dashboardData} = useDashboardsStore()

  const navigate = useNavigate()

  useEffect(() => {
    // MAKE SURE IMAGE URL IS NOT 404
    var img = new Image()
    img.src = selectedAccountsUsers.account.image || ''

    // if image is found
    img.onload = function () {
      setImageExists(true)
    }

    // if image is not found
    img.onerror = function () {
      setImageExists(false)
    }
  }, [selectedAccountsUsers])

  useEffect(() => {
    // MAKE SURE IMAGE URL IS NOT 404
    var img = new Image()
    img.src = adminAccount?.image || ''
  }, [adminAccount])

  const handleSwitchAccount = () => {
    // get account from search results
    switchUserToAdmin()
    navigate('/dashboard')
  }

  const {config} = useLayout()
  return (
    <div className='d-flex flex-row justify-content-between w-100 flex-grow-1'>
      {/* page details and pagination */}
      {getCurrentAccount()?.plan !== 'skybookings' && (
        <div className='d-flex align-items-center me-2'>
          {/* normal menu */}
          {!isMobileDevice() && (
            <div className='d-flex flex-column'>
              <h1 className='fs-2 fw-bolder text-dark mb-0'>
                {isMobileDevice() ? trimCharacters(currentPage, 5) : currentPage}
              </h1>
              <Breadcrumbs breadcrumbs={breadcrumbs} className='fs-7 text-gray-400' />
            </div>
          )}
        </div>
      )}

      {/* create kpis items */}
      {!isMobileDevice() && getCurrentAccount()?.plan === 'skybookings' && (
        <div className='d-flex align-items-center flex-wrap me-1'>
          {/* upcoming events */}
          <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3'>
            <KTIcon iconName='calendar' iconType='outline' className='fs-5 text-dark me-2' />
            <div className='fs-7 fw-bold text-dark me-2'>
              {formatNumber(dashboardData?.adminKpis?.totalUpcomingEvents, 0, false)}
            </div>
            <div className='fs-7 fw-bold text-muted'>Upcoming</div>
          </div>

          {/* events */}
          <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3'>
            <KTIcon iconName='calendar-8' iconType='outline' className='fs-5 text-dark me-2' />
            <div className='fs-7 fw-bold text-dark me-2'>
              {formatNumber(dashboardData?.adminKpis?.totalEvents, 0, false)}
            </div>
            <div className='fs-7 fw-bold text-muted'>Events</div>
          </div>

          {/* tickets */}
          <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3'>
            <KTIcon iconName='tag' iconType='outline' className='fs-5 text-dark me-2' />
            <div className='fs-7 fw-bold text-dark me-2'>
              {formatNumber(dashboardData?.adminKpis?.totalTickets, 0, true)}
            </div>
            <div className='fs-7 fw-bold text-muted me-2'>Tickets</div>
            <div className=' fs-7 fw-bold text-dark'>
              {formatMoney(
                dashboardData?.adminKpis?.totalSales,
                selectedAccountsUsers.account.currency,
                0,
                true
              )}
            </div>
          </div>

          {/* payout value */}
          <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3'>
            <div className=' fs-7 fw-bold text-dark me-2'>
              {formatNumber(dashboardData?.adminKpis?.totalPayouts, 0, true)}
            </div>
            <div className='fs-7 fw-bold text-muted me-2'>Payouts</div>
            <div className=' fs-7 fw-bold text-dark'>
              {formatMoney(
                dashboardData?.adminKpis?.totalPayoutsValue,
                selectedAccountsUsers.account.currency,
                0,
                true
              )}
            </div>
          </div>

          {/* users */}
          <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3'>
            <KTIcon iconName='user' iconType='outline' className='fs-5 text-dark me-2' />
            <div className=' fs-7 fw-bold text-dark me-2'>
              {formatNumber(
                dashboardData?.adminKpis?.totalUsers,

                0,
                true
              )}
            </div>
            <div className='fs-7 fw-bold text-muted'>Users</div>
          </div>

          {/* accounts */}
          <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3'>
            <KTIcon iconName='security-user' iconType='outline' className='fs-5 text-dark me-2' />
            <div className=' fs-7 fw-bold text-dark me-2'>
              {formatNumber(dashboardData?.adminKpis?.totalAccounts, 0, true)}
            </div>
            <div className='fs-7 fw-bold text-muted'>Accounts</div>
          </div>

          {/* patrons */}
          <Tippy
            placement='bottom'
            content={`${formatNumber(dashboardData?.adminKpis?.totalPatrons, 0, false)} Patrons`}
          >
            <div className='cursor-pointer d-flex align-items-center p-2 bg-gray-200 rounded me-3'>
              <KTIcon iconName='users' iconType='outline' className='fs-5 text-dark me-2' />
              <div className='fs-7 fw-bold text-dark me-2'>
                {formatNumber(dashboardData?.adminKpis?.totalPatrons, 0, true)}
              </div>
              <div className='fs-7 fw-bold text-muted'>Patrons</div>
            </div>
          </Tippy>
        </div>
      )}

      {/* acccount nav */}
      <div className='app-navbar flex-shrink-0'>
        {getCurrentAccount()?.plan !== 'skybookings' && (
          <>
            <div className='app-navbar-item'>
              <CreateMenu />
            </div>
          </>
        )}

        {/* 

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

        {/* <div className='app-navbar-item ms-2'>
          <button
            id='kt_drawer_chat_toggle'
            type='button'
            className={'btn btn-sm btn-icon btn-outline btn-active-secondary '}
          >
            <KTIcon iconName='abstract-45' iconType='outline' className={'fs-2 text-dark'} />
          </button>
        </div> */}

        {!adminAccount && getCurrentAccount()?.plan === 'skybookings' && <Search />}
        {adminAccount && (
          <>
            <div className={clsx('app-navbar-item', itemClass)}>
              <div
                onClick={handleSwitchAccount}
                className={clsx('cursor-pointer symbol', userAvatarClass)}
              >
                <span className='symbol-label bg-warning text-light fw-bolder'>
                  <KTIcon iconName='left' iconType='solid' className='text-dark fs-3' />
                </span>
              </div>
            </div>
          </>
        )}

        {/* <div className={clsx('app-navbar-item', itemClass)}>
          <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-secondary btn-custom')} />
        </div> */}

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            {imageExists === true ? (
              <img src={selectedAccountsUsers.account.image} alt='' />
            ) : (
              <span className='symbol-label bg-dark text-light fw-bolder'>
                {selectedAccountsUsers.account.name.charAt(0).toUpperCase()}
              </span>
            )}
          </div>
          <HeaderUserMenu />
        </div>

        {config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTIcon iconName='text-align-left' className={btnIconClass} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {Navbar}
