import {Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {FormDataQuery, FormModel, initialFormDataQuery, useFormStore} from './core/formStore'
import SwitchInput from '../../../_components/SwitchInput'
import FormBuilder from './partials/FormBuilder'
import {BreadcrumbModel, useAppSettingsStore} from '../../modules/settings/core/appSettingsStore'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {Link, useParams} from 'react-router-dom'
import {
  getDirtyValues,
  pluralise,
  removeHtmlTags,
  trimCharacters,
  trimString,
} from '../../../_helpers/_helpers'
import toast, {Toaster} from 'react-hot-toast'
import FormRender from './partials/FormRender'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {FormStatusSwitcher} from './partials/FormStatusSwitcher'
import {capitalizeFirstLetter} from '../../../_helpers/_templateHelpers'
import {Modal} from 'react-bootstrap'
import Tippy from '@tippyjs/react'
import FormDataTable from './partials/FormDataTable'
import Swal from 'sweetalert2'
import EventHandleInput from '../../../_components/EventHandleInput'
import ChatModal from '../../modules/chat/ChatModal'

const formSchema = Yup.object().shape({
  name: Yup.string().required('Form name is required'),
  description: Yup.string(),
  handle: Yup.string().required('Form handle is required'),
  fields: Yup.array().min(1, 'Add at least one field'),
})

type FormEditorView = 'editor' | 'responses' | 'results'

const FormEditor: React.FC = () => {
  const {
    currentForm,
    formData,
    exportFormData,
    queryFormData,
    unsetCurrentForm,
    postForm,
    patchForm,
    getForm,
  } = useFormStore()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [copyStatus, setCopyStatus] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const {formId} = useParams()
  const [view, setView] = useState<FormEditorView>('editor')
  const {appSettings} = useAppSettingsStore()
  const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false)
  const [exporting, setExporting] = useState<boolean>(false)
  const [showFieldsModal, setShowFieldsModal] = useState<boolean>(false)

  // SETUP BREADCRUMBS
  useEffect(() => {
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Forms',
        title: 'All',
        link: '/forms',
      },
    ]

    if (currentForm) {
      crumbs.push({
        object: 'Forms',
        title: trimCharacters(currentForm.name, 15),
        link: `/forms/${currentForm.id}`,
      })
    } else {
      crumbs.push({
        object: 'forms',
        title: 'Create',
        link: '/forms/create',
      })
    }

    setBreadcrumbs(crumbs)
    setCurrentPage('Form')

    // eslint-disable-next-line
  }, [currentForm])

  //   LOAD CURRENT FORM
  useEffect(() => {
    if (formId) {
      setIsLoading(true)
      getForm(formId).finally(() => {
        setIsLoading(false)
      })
    }
    unsetCurrentForm()

    // eslint-disable-next-line
  }, [formId])

  const handleSubmit = async (values: FormModel) => {
    setSubmitting(true)
    try {
      if (!values.id) {
        const payload = {
          ...values,
          account: selectedAccountsUsers.account,
        }
        const form = await postForm(payload)

        // update url
        window.history.replaceState({}, '', `/forms/${form.id}`)

        toast.success('Form created successfully')
      } else {
        // only send dirty values
        const dirty = getDirtyValues(values, currentForm)
        await patchForm(values.id, dirty)
        toast.success('Form updated successfully')
      }
    } catch (e) {
      toast.error('Failed to save form')
    } finally {
      setSubmitting(false)
    }
  }

  const handleExport = async () => {
    if (!currentForm.id) return

    try {
      setExporting(true)
      const payload = {
        ...initialFormDataQuery,
      }
      const url = await exportFormData(currentForm.id, payload)

      // detect safari
      if (/Apple/.test(navigator.userAgent)) {
        Swal.fire({
          icon: 'info',
          title: 'Exporting',
          text: 'Safari may open the file in a new tab instead of downloading it.  Please go to the new tab and click file > save or cmd + s if this happens.',
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(url, '_blank', 'noopener,noreferrer')?.focus()
          }
        })
      } else {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    } catch (e) {
      toast.error('Failed to export form data')
    } finally {
      setExporting(false)
    }
  }

  const handleQueryFormData = async (query: Partial<FormDataQuery>) => {
    if (!currentForm.id) return
    try {
      const payload = {...initialFormDataQuery, ...query, form: currentForm.id}

      await queryFormData(currentForm.id, payload)
    } catch (e) {
      toast.error('Failed to query form data')
    }
  }

  const handleCopyToClipboard = () => {
    let content: string = ''
    if (currentForm.handle) {
      content = `${appSettings.baseUrl}/${currentForm.handle}`
    } else {
      content = `${appSettings.baseUrl}/f/${currentForm.id}`
    }

    //  copy to clipboard
    setCopyStatus('copying')

    navigator.clipboard
      .writeText(content)
      .then(() => {
        toast.success('Linked copied to clipboard')
        setCopyStatus('copied')
      })
      .catch((err) => {
        toast.error('Failed to copy to clipboard')
        setCopyStatus('failed')
      })
      .finally(() => {
        // set timeout to reset copy status
        setTimeout(() => {
          setCopyStatus('')
        }, 2000)
      })
  }

  return (
    <>
      {isLoading && <PageLoadingSpinner />}
      <Toaster position='top-center' />
      <Formik
        validationSchema={formSchema}
        enableReinitialize
        initialValues={currentForm}
        onSubmit={handleSubmit}
      >
        {(form) => (
          <Form>
            <div>
              {/* HEADER */}
              <div className='d-flex justify-items-between w-100 mb-10'>
                {/* form status */}
                <div className='d-flex flex-grow-1'>
                  {/* switch views */}

                  {/* nav pills */}
                  <ul
                    className='nav nav-pills nav-pills-line bg-light border border-secondary rounded p-1'
                    role='tablist'
                  >
                    <li className='nav-item'>
                      <button
                        type='button'
                        className={clsx('btn btn-sm btn-active-secondary', {
                          active: view === 'editor',
                        })}
                        data-bs-toggle='tab'
                        onClick={() => setView('editor')}
                        role='tab'
                      >
                        Editor
                      </button>
                    </li>
                    <li className='nav-item'>
                      <button
                        disabled={!currentForm.stats.responses}
                        type='button'
                        className={clsx('btn btn-sm btn-active-secondary', {
                          active: view === 'responses',
                          'text-muted': !currentForm.stats.responses,
                        })}
                        data-bs-toggle='tab'
                        onClick={() => {
                          setView('responses')
                          handleQueryFormData({page: 1})
                        }}
                        role='tab'
                      >
                        {pluralise(currentForm.stats.responses, 'Response')}
                        {currentForm.stats.responses > 0 && (
                          <span className='badge bg-gray-400 ms-2'>
                            {currentForm.stats.responses}
                          </span>
                        )}
                      </button>
                    </li>
                  </ul>

                  {/* something */}
                  {currentForm.stats.responses > 0 && (
                    <ChatModal
                      topic={{
                        object: 'form',
                        title: currentForm.name,
                      }}
                      developer={`${JSON.stringify(currentForm)}`}
                    />
                  )}
                </div>

                {/* toolbox */}
                <div className='d-flex align-items-center'>
                  <FormStatusSwitcher
                    isLoading={submitting}
                    menuPlacement='bottom-start'
                    toggleBtnLabel={`${capitalizeFirstLetter(currentForm.status)}`}
                    toggleBtnClass='btn-outline btn-sm btn-active-secondary me-2'
                    onSwitchStatus={(status) => {
                      form.setFieldValue('status', status)
                      form.submitForm()
                    }}
                  />

                  <button
                    disabled={!form.isValid}
                    onClick={() => form.submitForm()}
                    type='button'
                    className={clsx('btn btn-outline btn-sm btn-active-secondary me-2', {
                      'btn-info':
                        form.isValid &&
                        Object.keys(getDirtyValues(form.values, form.initialValues)).length > 0,
                    })}
                  >
                    {form.isSubmitting && (
                      <span className='spinner-border spinner-border-sm me-1' role='status'></span>
                    )}
                    Save Form
                  </button>
                  {currentForm.id && (
                    <>
                      <Tippy content='Preview Form'>
                        <Link
                          target='_blank'
                          to={`/f/${form.values.id}/?mode=preview`}
                          className='btn btn-icon btn-outline btn-sm btn-active-secondary me-2'
                        >
                          <KTIcon
                            iconName='eye'
                            iconType='outline'
                            className={clsx('text-active-light', {
                              'text-dark': form.values.id,
                              'text-muted': submitting && !form.values?.id,
                            })}
                          />
                        </Link>
                      </Tippy>

                      {/* COPY */}
                      <Tippy content='Copy form link'>
                        <button
                          type='button'
                          disabled={copyStatus === 'copying' || !currentForm.id}
                          className='btn-icon btn btn-sm btn-outline btn-active-secondary me-2'
                          onClick={handleCopyToClipboard}
                        >
                          {copyStatus === '' && (
                            <KTIcon
                              iconName='fasten'
                              iconType='outline'
                              className='svg-icon svg-icon-2 text-dark'
                            />
                          )}

                          {copyStatus === 'copying' && (
                            <span
                              className='spinner-border spinner-border-sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}

                          {copyStatus === 'copied' && (
                            <KTIcon
                              iconName='check-circle'
                              iconType='solid'
                              className='svg-icon fs-3 svg-icon-2 text-success text-dark'
                            />
                          )}

                          {copyStatus === 'failed' && (
                            <KTIcon
                              iconName='cross-circle'
                              iconType='solid'
                              className='svg-icon fs-3 svg-icon-2 text-danger text-dark'
                            />
                          )}
                        </button>
                      </Tippy>

                      {/* EXPORT */}
                      <Tippy content='Export Responses' placement='top'>
                        <button
                          type='button'
                          className='btn btn-sm btn-icon btn-outline btn-active-secondary'
                          onClick={handleExport}
                          disabled={currentForm.stats.responses === 0}
                        >
                          {exporting ? (
                            <span className='spinner-border spinner-border-sm'></span>
                          ) : (
                            <KTIcon
                              iconName='cloud-download'
                              className={clsx('fs-2 p-0', {
                                'text-muted': currentForm.stats.responses === 0,
                                'text-dark': currentForm.stats.responses > 0,
                              })}
                              iconType='outline'
                            />
                          )}
                        </button>
                      </Tippy>
                    </>
                  )}
                </div>
              </div>

              {/* VIEW:: RESPONSES */}
              {view === 'responses' && (
                <div className='fv-row row h-100'>
                  <div className='col-12'>
                    <div className='d-flex justify-content-between mb-10'>
                      <div className='d-flex flex-column'>
                        <span className='fs-5 fw-bold'>
                          {pluralise(currentForm.stats.responses, 'Response')}
                        </span>
                        <span className='fs-7 text-muted'>
                          {`Viewing ${currentForm.stats.responses} responses`}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex flex-rows align-items-center'>
                      <FormDataTable
                        onPageChange={(page) => handleQueryFormData({page})}
                        formData={formData}
                        form={currentForm}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* VIEW:: RESULTS */}
              {view === 'results' && (
                <div className='fv-row row h-100'>
                  <div className='col-12'>
                    <div className='d-flex justify-content-between mb-10'>
                      <div className='d-flex flex-column'>
                        <span className='fs-5 fw-bold'>Form Results</span>
                        <span className='fs-7 text-muted'>View form results</span>
                      </div>
                      <div className='d-flex flex-rows align-items-center'></div>
                    </div>
                  </div>
                </div>
              )}

              {/* VIEW:: EDITOR */}
              {view === 'editor' && (
                <div className='fv-row row h-100'>
                  <div className='col-7'>
                    {/* HNAME */}
                    <div className='form-group align-items-center d-flex bg-light border border-secondary justify-content-between rounded p-5 mb-5'>
                      <div className='d-flex flex-column'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-3', {
                              'bg-secondary': !form.values.name,
                              'bg-success': form.values.name,
                              'bg-danger': form.errors.name,
                            })}
                          ></div>
                          <label className='form-label fw-bold fs-5 mb-0' htmlFor='name'>
                            Form Name
                          </label>
                        </div>
                        <span className='fs-7 text-muted'>Enter the form name</span>
                      </div>
                      <Field
                        id='name'
                        name='name'
                        type='text'
                        placeholder='Form Name'
                        onBlur={() => {
                          if (form.values.id) form.submitForm()
                        }}
                        className='form-control w-300px form-control form-control-lg'
                      />
                    </div>

                    <div className='form-group align-items-center d-flex bg-light border border-secondary justify-content-between rounded p-5 mb-5'>
                      <div className='d-flex flex-column'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-3', {
                              'bg-secondary': !form.values.handle,
                              'bg-success': form.values.handle,
                              'bg-danger': form.errors.handle,
                            })}
                          ></div>
                          <label className='form-label fw-bold fs-5 mb-0' htmlFor='name'>
                            Link
                          </label>
                        </div>
                        <span className='fs-7 text-muted'>Customise the form link</span>
                      </div>

                      <div className='d-flex flex-column'>
                        <div className='d-flex'>
                          <div className='w-375px me-1'>
                            <EventHandleInput
                              inputGroupText={`${appSettings.baseUrl}`}
                              exclude={currentForm.handle}
                              name='handle'
                              className='form-control form-control-lg '
                              onBlur={() => {
                                if (form.values.id) form.submitForm()
                              }}
                            />
                          </div>

                          <Tippy content='Copy form link'>
                            <button
                              type='button'
                              disabled={copyStatus === 'copying' || !currentForm.id}
                              className='btn-icon btn btn-outline btn-active-secondary'
                              onClick={handleCopyToClipboard}
                            >
                              {copyStatus === '' && (
                                <KTIcon
                                  iconName='fasten'
                                  iconType='outline'
                                  className='svg-icon svg-icon-2 text-dark'
                                />
                              )}

                              {copyStatus === 'copying' && (
                                <span
                                  className='spinner-border spinner-border-sm'
                                  role='status'
                                  aria-hidden='true'
                                />
                              )}

                              {copyStatus === 'copied' && (
                                <KTIcon
                                  iconName='check-circle'
                                  iconType='solid'
                                  className='svg-icon fs-3 svg-icon-2 text-success text-dark'
                                />
                              )}

                              {copyStatus === 'failed' && (
                                <KTIcon
                                  iconName='cross-circle'
                                  iconType='solid'
                                  className='svg-icon fs-3 svg-icon-2 text-danger text-dark'
                                />
                              )}
                            </button>
                          </Tippy>
                        </div>
                        {form.errors.handle && (
                          <div className='text-danger fs-7 mt-2'>{form.errors.handle}</div>
                        )}
                      </div>
                    </div>

                    {/* DESCRIPTION */}
                    <div className='form-group align-items-center d-flex bg-light border border-secondary justify-content-between rounded p-5 mb-5'>
                      <div className='d-flex flex-column'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-3', {
                              'bg-secondary': !form.values.description,
                              'bg-success': form.values.description,
                              'bg-danger': form.errors.description,
                            })}
                          ></div>
                          <label className='form-label fw-bold fs-5 mb-0' htmlFor='name'>
                            Description
                          </label>
                        </div>
                        <span className='fs-7 text-muted'>
                          {trimString(removeHtmlTags(form.values.description ?? ''), 10)}
                        </span>
                      </div>
                      <button
                        onClick={() => setShowDescriptionModal(true)}
                        type='button'
                        className='btn btn-outline btn-sm btn-active-secondary'
                      >
                        Edit Description
                      </button>

                      <Modal
                        show={showDescriptionModal}
                        onHide={() => setShowDescriptionModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Form Description</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='d-flex flex-column'>
                            <p className='text-muted fs-7'>
                              Describe your form, its purpose or what your want your respondents to
                              do. This description will be displayed at the top of the form.
                            </p>
                            <Field as='textarea' className='form-control' name='description' />
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            type='button'
                            className='btn btn-sm btn-outline btn-active-dark'
                            onClick={() => {
                              if (form.values.id) form.submitForm()
                              setShowDescriptionModal(false)
                            }}
                          >
                            Save changes
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>

                    {/* ANONYMOUS */}
                    {/* <div className='form-group align-items-center d-flex bg-light border border-secondary justify-content-between rounded p-5 mb-5'>
                      <div className='d-flex flex-column'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px bg-success h-15px rounded rounded-circle me-3')}
                          ></div>
                          <label className='form-label fw-bold fs-5 mb-0' htmlFor='name'>
                            Allow Anonymous Entries
                          </label>
                        </div>
                        <p className='fs-7 text-muted'>
                          If the switch is off, respondents will be required to identify themselves
                          using the OTP method.
                        </p>
                      </div>

                      <SwitchInput onChange={() => form.submitForm()} name='allowAnonymous' />
                    </div> */}

                    {/* MULTIPLE */}
                    <div className='form-group align-items-center d-flex bg-light border border-secondary justify-content-between rounded p-5 mb-5'>
                      <div className='d-flex flex-column'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px bg-success h-15px rounded rounded-circle me-3')}
                          ></div>
                          <label className='form-label fw-bold fs-5 mb-0' htmlFor='name'>
                            Allow Multiple Entries
                          </label>
                        </div>
                        <p className='fs-7 text-muted'>
                          If the switch is on, respondents will be able to submit the form multiple
                          times.
                        </p>
                      </div>

                      <SwitchInput onChange={() => form.submitForm()} name='allowMultiple' />
                    </div>

                    {/* FIELDS */}
                    <div className='form-group justify-content-between d-flex align-items-center bg-light border border-secondary rounded p-5 mb-5'>
                      <div className='d-flex flex-column w-100'>
                        <div className='fw-bold mb-2 fs-5 d-flex align-items-center'>
                          <div
                            className={clsx('w-15px h-15px rounded rounded-circle me-3', {
                              'bg-success': form.values.fields.length > 0,
                              'bg-danger': form.values.fields.length <= 0,
                            })}
                          ></div>
                          <label className='form-label fw-bold fs-5 mb-0' htmlFor='name'>
                            Form Fields
                          </label>
                        </div>
                        {}
                        <p className='fs-7 text-muted'>
                          {currentForm.stats.responses > 0
                            ? 'You cannot edit the fields of a form that has responses'
                            : form.values.fields.length > 0
                            ? `This form has ${form.values.fields.length} fields`
                            : 'Add fields to the form'}
                        </p>
                      </div>

                      {/* FIELDS  */}
                      <button
                        type='button'
                        className='btn btn-outline btn-sm btn-active-secondary text-nowrap'
                        onClick={() => setShowFieldsModal(true)}
                      >
                        Edit Fields
                      </button>

                      <Modal show={showFieldsModal} onHide={() => setShowFieldsModal(false)}>
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Form Fields</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <FormBuilder
                            onSubmit={(fields) => form.setFieldValue('fields', fields)}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            type='button'
                            className='btn btn-sm btn-outline btn-active-secondary me-2'
                            onClick={() => {
                              setShowFieldsModal(false)
                              form.resetForm()
                            }}
                          >
                            Close
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-outline btn-active-dark'
                            onClick={() => {
                              form.submitForm()
                              setShowFieldsModal(false)
                            }}
                          >
                            Save changes
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                  <div className='p-5 col-5 rounded position-sticky top-0 h-100 bg-light border border-secondary'>
                    <div
                      className='shadow shadow-sm rounded p-10'
                      style={{
                        height: 'calc(100vh - 250px)',
                        overflowY: 'auto',
                        top: '20px',
                        position: 'sticky',
                      }}
                    >
                      <div className='d-flex flex-column align-items-start'>
                        <div className='badge badge-light-info border border-info flex-shrink-1 mb-3'>
                          Preview
                        </div>
                        <h3 className='form-label fw-bold fs-3 mb-3'>
                          {form.values.name ?? 'Form Preview'}
                        </h3>
                        <p className='fs-7 text-muted mb-4'>
                          {form.values.description ?? 'Preview the form'}
                        </p>
                      </div>
                      <div className='border border-2 border-dark'></div>

                      <FormRender mode={'preview'} fields={form.values.fields} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default FormEditor
