import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import axios from 'axios'
import {pick} from '../../../../_helpers/_helpers'
import {
  _createClientOTP,
  _deleteClient,
  _deleteClientEvent,
  _getClientEvents,
  _getClientMe,
  _getClients,
  _patchClient,
  _postClient,
  _postClientAccount,
  _postClientEvent,
  _searchClient,
  _verifyClientOTP,
  CreateClientType,
} from './clientsApi'
import {AccountModel} from '../../settings/core/accountsUsersStore'
import {EventModel} from '../../events/core/eventsStore'

export type ClientModel = {
  id: string | null
  name: string
  email: string
  phone: number
  OTP: string | null
}

export type ClientEventModel = {
  id: string | null
  client: ClientModel
  event: EventModel
}

export const initialClient: ClientModel = {
  id: null,
  name: '',
  email: '',
  phone: 27,
  OTP: '',
}

export type Pagination = {
  limit: number
  page: number
  totalPages?: number
  totalResults?: number
  event?: string
  account?: string
  client?: string
}

type ClientStore = {
  clients: ClientModel[]
  pagination: Pagination
  currentClient: ClientModel
  isClientLoggedIn: boolean
  clientAccounts: AccountModel[]
  clientEvents: ClientEventModel[]
  postClient: (eventId: string, client: ClientModel) => Promise<ClientModel> // Updated return type
  patchClient: (client: Partial<ClientModel>) => Promise<ClientModel> // Updated return type
  deleteClient: (clientId: string) => Promise<void>
  createClientOTP: (type: string, data: string) => Promise<string>
  verifyOTP: (OTP: string) => Promise<void>
  unsetClient: () => void
  setClientFromToken: (clientToken: string) => Promise<void>
  loadClientFromToken: () => Promise<void>
  searchClient: (search) => Promise<ClientModel>
  setCurrentClient: (client: ClientModel) => void
  getClients: (query: any) => Promise<ClientModel[]>
  postClientEvent: (clientId: string, eventId: string) => Promise<void>
  deleteClientEvent: (clientId: string, eventId: string) => Promise<void>
  getClientEvents: (args: Pagination) => Promise<ClientEventModel[]>
  postClientAccount: (clientId: string, accountId) => Promise<void>
}

const createStore: StateCreator<ClientStore> = (set, get) => ({
  clients: [],
  currentClient: {...initialClient},
  isClientLoggedIn: false,
  pagination: {limit: 10, page: 1, totalPages: 1, totalResults: 0},
  clientAccounts: [],
  clientEvents: [],

  setCurrentClient: (client: ClientModel) => {
    set((state) => ({
      currentClient: {
        ...state.currentClient,
        ...client,
      },
    }))
  },

  searchClient: async (search) => {
    const r = await _searchClient(search)
    return r.data
  },

  postClient: async (eventId, client: ClientModel) => {
    let savedClient: any
    const payload: CreateClientType = {
      name: client.name,
      phone: client.phone,
      email: client.email,
    }
    savedClient = await _postClient(eventId, payload)

    set((state) => ({
      currentClient: {
        ...state.currentClient,
        ...savedClient.data.client,
      },
      clients: [...state.clients, savedClient.data.client],
    }))

    return get().currentClient
  },

  patchClient: async (client: Partial<ClientModel>) => {
    let savedClient: any
    const clientId = client.id
    const payload = pick(client, ['name', 'phone', 'email'])
    delete client.id
    savedClient = await _patchClient(clientId, payload)

    set((state) => ({
      currentClient: {
        ...state.currentClient,
        ...savedClient.data,
      },
    }))

    return get().currentClient
  },

  deleteClient: async (clientId: string) => {
    await _deleteClient(clientId)

    set((state) => ({
      currentClient: {...initialClient},
    }))
  },

  createClientOTP: async (type: string, data: string) => {
    // Implement this function
    const response = await _createClientOTP(type, data)
    return response.data.OTP
  },

  verifyOTP: async (OTP: string) => {
    // Implement this function
    const response = await _verifyClientOTP(parseFloat(OTP))

    // save clientToken to local storage
    const {setClientFromToken} = get()
    setClientFromToken(response.data.clientToken)
  },

  unsetClient: () => {
    // clear the loca storage
    localStorage.removeItem('clientToken')

    // remove axios auth token
    axios.interceptors.request.use((config) => {
      delete config.headers.Authorization
      return config
    })

    // update the store
    set((state) => ({
      currentClient: {...initialClient},
      isClientLoggedIn: false,
    }))
  },

  setClientFromToken: async (clientToken: string) => {
    // set in local storage
    // if item exists in local storage, delete it
    if (localStorage.getItem('clientToken')) localStorage.removeItem('clientToken')
    localStorage.setItem('clientToken', clientToken)

    // remove auth token and add another one if it exists
    axios.interceptors.request.use((config) => {
      delete config.headers.Authorization
      const pt = localStorage.getItem('clientToken')
      if (pt) config.headers.Authorization = `Bearer ${pt}`
      return config
    })

    // get client
    try {
      const r = await _getClientMe()
      const newClient = r.data

      set((state) => ({
        currentClient: newClient,
        isClientLoggedIn: true,
      }))
    } catch (error: any) {
      //   localStorage.removeItem('clientToken')
      //   axios.interceptors.request.use((config) => {
      //     delete config.headers.Authorization
      //     return config
      //   })
    }
  },

  loadClientFromToken: async () => {
    const clientToken = localStorage.getItem('clientToken')

    if (clientToken) {
      const {setClientFromToken} = get()
      await setClientFromToken(clientToken)
    }
  },

  getClients: async (query) => {
    const response = await _getClients(query)

    // map response to clients
    const clients = response.data.results.map((data) => {
      return {
        ...initialClient,
        ...data.client,
      }
    })

    // update pagination
    const pagination: Pagination = {
      limit: response.data.limit,
      page: response.data.page,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }

    // set clients in store
    set({clients, pagination})

    // return clients
    return clients
  },

  // CLIENT EVENT
  postClientEvent: async (clientId: string, eventid: string) => {
    // avoid adding client if event already exists
    const {clientEvents} = get()
    const exists = clientEvents.find((ce) => ce.client.id === clientId)
    if (exists) return

    // post client event
    const response = await _postClientEvent(clientId, eventid)

    // update the store
    set((state) => ({
      clientEvents: response.data,
    }))

    // return data
    return response.data
  },

  deleteClientEvent: async (clientId: string, eventId: string) => {
    // delete client event
    await _deleteClientEvent(clientId, eventId)

    // get clients
    const {clientEvents} = get()
    const newClientEvents = clientEvents.filter((ce) => ce.client.id !== clientId)

    // remove matching clientEvent from store
    set((state) => ({
      clientEvents: newClientEvents,
    }))
  },

  getClientEvents: async (args: Pagination) => {
    const payload = pick(args, ['limit', 'page', 'event', 'client', 'account'])
    const response = await _getClientEvents(payload)

    // map response to client events
    const clientEvents = response.data.results.map((data) => {
      return {
        ...data,
      }
    })

    // update pagination
    const pagination: Pagination = {
      limit: response.data.limit,
      page: response.data.page,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }

    // set client events in store
    set({clientEvents, pagination})

    // return client events
    return clientEvents
  },

  // CLIENT ACCOUNT
  postClientAccount: async (clientId: string, accountId: string) => {
    const response = await _postClientAccount(clientId, accountId)

    // update the store
    set((state) => ({
      clientAccounts: response.data,
    }))

    // return data
    return response.data
  },
})

export const clientStore = create(devtools(createStore))
export const useClientStore = clientStore
