/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {KTIcon} from '../../helpers'
import {markdownToHtml} from '../../../_helpers/_helpers'
import {useChatStore} from '../../../app/modules/chat/chatStore'
import {useFormStore} from '../../../app/pages/forms/core/formStore'
import {useAccountsUsersStore} from '../../../app/modules/settings/core/accountsUsersStore'

type Props = {
  isDrawer?: boolean
}

const ChatInner: FC<Props> = ({isDrawer = false}) => {
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [message, setMessage] = useState('')
  const {discussion, developer, topic, postPrompt, setDiscussion} = useChatStore()
  const {currentForm} = useFormStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const discussionBodyRef = useRef<HTMLDivElement>(null)
  const [visibleResponses, setVisibleResponses] = useState<Record<number, string>>({})
  const intervalsRef = useRef<Record<number, NodeJS.Timeout>>({})

  useEffect(() => {
    // setDiscussion([])
    // setVisibleResponses({})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Cleanup intervals on unmount
    return () => {
      Object.values(intervalsRef.current).forEach(clearInterval)
    }
  }, [])

  useEffect(() => {
    if (discussionBodyRef.current) {
      discussionBodyRef.current.scrollTop = discussionBodyRef.current.scrollHeight
    }
  }, [discussion, visibleResponses])

  useEffect(() => {
    discussion.forEach((msg, index) => {
      if (
        msg.role === 'assistant' &&
        msg.status === 'completed' &&
        !visibleResponses.hasOwnProperty(index)
      ) {
        const fullText = msg.text
        let currentCharIndex = 0

        intervalsRef.current[index] = setInterval(() => {
          if (currentCharIndex >= fullText.length) {
            clearInterval(intervalsRef.current[index])
            delete intervalsRef.current[index]
            return
          }

          setVisibleResponses((prev) => ({
            ...prev,
            [index]: fullText.substring(0, currentCharIndex + 1),
          }))

          currentCharIndex++
        }, 10)
      }
    })

    return () => {
      Object.values(intervalsRef.current).forEach(clearInterval)
      intervalsRef.current = {}
    }

    // eslint-disable-next-line
  }, [discussion])

  const handleSendPrompt = async () => {
    if (!currentForm.id) return

    setWaitingForResponse(true)
    setDiscussion([
      ...discussion,
      {
        id: null,
        text: message,
        role: 'user',
        previousResponseId: discussion[discussion.length - 1]?.id || null,
        status: 'completed',
      },
      {
        id: null,
        text: 'thinking...',
        role: 'assistant',
        previousResponseId: discussion[discussion.length - 1]?.id || null,
        status: 'pending',
      },
    ])

    try {
      await postPrompt(selectedAccountsUsers.account.id, message, developer)
    } catch (error) {
      console.error('Error posting prompt:', error)
      setDiscussion([
        ...discussion,
        {
          id: null,
          text: 'Error occurred while processing your request.',
          role: 'assistant',
          status: 'error',
        },
      ])
    } finally {
      setWaitingForResponse(false)
    }
  }

  return (
    <div
      className='card-body position-relative'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5 h-100', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
      >
        {discussion.map((message, index) => {
          const templateAttr = {}
          if (message.text) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.role}`,
            })
          }
          const contentClass = `d-flex justify-content-${
            message.role === 'user' ? 'end' : 'start'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {
                'd-none': message.role === 'system',
              })}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.role === 'user' ? 'end' : 'start'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.role === 'user' ? (
                    <>
                      <div className='ms-3'>
                        <div className='bg-dark rounded text-light fw-bolder p-2 fs-7'>You</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <div className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'>
                          {message.status === 'pending' ? (
                            <div
                              className='spinner-grow spinner-grow-sm ms-3 text-secondary'
                              role='status'
                            >
                              <span className='visually-hidden'>Loading...</span>
                            </div>
                          ) : (
                            <div className='bg-dark rounded text-light fw-bolder p-2 fs-7'>sky</div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {message.status !== 'pending' && (
                  <div
                    className={clsx(
                      'text-dark fw-semibold mw-lg-400px',
                      `text-${message.role === 'user' ? 'end' : 'start'}`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(
                        message.role === 'assistant' && message.status === 'completed'
                          ? visibleResponses[index] || ''
                          : message.text
                      ),
                    }}
                  ></div>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='p-3 fs-5 border-0 no-focus w-100'
          disabled={waitingForResponse}
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              handleSendPrompt()
              setMessage('')
            }
            if (e.key === 'Escape') {
              setMessage('')
            }
            if (e.key === 'Enter' && e.shiftKey) {
              setMessage((prev) => prev + '\n')
            }
          }}
          value={message}
          placeholder='Ask your question here'
        />

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <div className='p-2 rounded fw-semibold text-dark bg-secondary'>
            {topic?.object}:{topic?.title}
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-icon btn-sm btn-outline btn-active-dark me-2'
              style={{width: '30px', height: '30px', borderRadius: '50%'}}
              onClick={() => {
                setDiscussion([])
                setMessage('')
              }}
            >
              <KTIcon iconName='eraser' iconType='solid' className='fs-5 ' />
            </button>
            <button
              type='button'
              disabled={waitingForResponse}
              className='btn btn-icon btn-sm bg-gray-400 bg-hover-dark'
              style={{width: '30px', height: '30px', borderRadius: '50%'}}
              onClick={handleSendPrompt}
            >
              {waitingForResponse ? (
                <div
                  className='spinner-border spinner-border-sm text-light'
                  role='status'
                  style={{width: '20px', height: '20px'}}
                >
                  <span className='visually-hidden'>Loading...</span>
                </div>
              ) : (
                <KTIcon iconName='send' iconType='solid' className='fs-5 text-light' />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChatInner}
