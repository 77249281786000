/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'

const DrawerMessenger: FC = () => {
  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div className='card w-100 rounded-0 position-relative' id='kt_drawer_chat_messenger'>
        <div
          className='card-header pe-5'
          id='card-header position-absolute position-fixed w-500px z-index-1 top-0 end-0 pe-5 glass-pane '
        >
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a
                href='#'
                className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
              >
                Brian Cox
              </a>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
              <KTIcon iconName='cross-circle' iconType='solid' className='fs-2' />
            </div>
          </div>
        </div>

        <ChatInner isDrawer={true} />
      </div>
    </div>
  )
}

export {DrawerMessenger}
